<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Ein neues Projekt erstellen</md-dialog-title>

    <md-dialog-content>
      <md-field>
        <label>Projektname</label>
        <md-input v-model="name" required />
        <span class="md-helper-text"
          >Bitte geben Sie hier den Namen für Ihr neues Projekt an.</span
        >
      </md-field>

      <md-field>
        <label>Referenz-ID</label>
        <md-input v-model="ref_id" required />
        <span class="md-helper-text"
          >Bitte geben Sie hier eine Referenz-ID für Ihr neues Projekt an.</span
        >
      </md-field>

      <md-field>
        <label>Projektstatus</label>
        <md-select v-model="status" required>
          <md-option value="pending">Ausstehend</md-option>
          <md-option value="active">Aktiv</md-option>
          <md-option value="inactive">Inaktiv</md-option>
        </md-select>
        <span class="md-helper-text"
          >Bitte geben Sie hier den Status für Ihr neues Projekt an.</span
        >
      </md-field>

      <md-field>
        <label>Projektbeschreibung</label>
        <md-textarea v-model="description" maxlength="200" md-autogrow />
        <span class="md-helper-text"
          >Bitte geben Sie hier eine kurze Beschreibung für Ihr neues Projekt
          an.</span
        >
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="createProject">Erstellen</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";

export default {
  name: "projectCreateDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      ref_id: "",
      status: "pending",
      description: "",
      public_access: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.reset();
    },
    createProject() {
      this.$emit("create-project", this.$data);
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 750px;
}
</style>
