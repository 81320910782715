<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="loading" />

    <!-- Content -->
    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar is-with-center>
        <template v-slot:left>
          <!-- Avatar -->
          <iq-avatar>folder</iq-avatar>

          <!-- Title -->
          <div class="md-title">Projektübersicht</div>
        </template>

        <template v-slot:center>
          <div class="search-wrapper">
            <input
              type="text"
              class="search-bar"
              placeholder="Nach einem Projekt suchen..."
              v-model="search"
              @input="searchProject"
            />

            <md-button
              class="md-icon-button md-accent search-reset-button"
              v-if="search.length > 0"
              @click="resetSearch"
            >
              <md-icon>clear</md-icon>
            </md-button>
          </div>
        </template>

        <template v-slot:right>
          <!-- Create project -->
          <md-button
            class="md-primary md-icon-button margin-0"
            @click="toggleDialog('create')"
            v-if="isComponentActive(['admin'])"
          >
            <md-icon>create_new_folder</md-icon>
            <md-tooltip md-direction="left">Neues Projekt erstellen</md-tooltip>
          </md-button>
        </template>
      </iq-toolbar>

      <!-- Content -->
      <div class="wrapper">
        <div class="wrapper--right width-100">
          <project-grid
            :projects="filteredProjects"
            @delete="handleProjectDelete"
            @error="toggleError"
          />
        </div>
      </div>
    </div>

    <!-- Dialogs -->
    <project-create-dialog
      :is-active="dialogs.create"
      @close="toggleDialog('create')"
      @create-project="createProject"
    />

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
    >
      <span>{{ successMessage }}</span>
    </md-snackbar>
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import successMixin from "@/mixins/successMixin";
import errorMixin from "@/mixins/errorMixin";
import { getUserProjects } from "@/services/api/user";
import ProjectGrid from "@/components/projects/projectGrid";
import ProjectCreateDialog from "@/components/projects/projectCreateDialog";
import { createProject } from "@/services/api/project";
import accessControlMixin from "@/mixins/accessControlMixin";

const toLower = (term) => {
  return term.toLowerCase();
};

const searchProjectsByTerm = (items, term) => {
  if (term) {
    return items.filter((item) => {
      return toLower(item.name).includes(toLower(term));
    });
  }
  return items;
};

export default {
  name: "Projects",
  components: { ProjectCreateDialog, ProjectGrid },
  mixins: [loadingMixin, successMixin, errorMixin, accessControlMixin],
  data() {
    return {
      projects: [],
      filteredProjects: [],
      search: "",
      dialogs: {
        create: false,
        filter: false,
      },
      filters: {
        status: "pending",
      },
    };
  },
  created() {
    this.getProjects();
  },
  methods: {
    async getProjects() {
      try {
        this.projects = await getUserProjects();
        this.filteredProjects = this.projects;
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
    async createProject(data) {
      try {
        await createProject(data);
        this.projects = await getUserProjects();
        this.filteredProjects = this.projects;
        this.toggleSuccess("Das neue Projekt wurder erfolgreich erstellt");
        this.toggleDialog("create");
      } catch (error) {
        console.error(error);
        this.toggleError(
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal"
        );
      }
    },
    searchProject() {
      this.filteredProjects = searchProjectsByTerm(this.projects, this.search);
    },
    resetSearch() {
      this.search = "";
      this.searchProject();
    },
    toggleDialog(id) {
      this.dialogs[id] = !this.dialogs[id];
    },
    handleProjectDelete() {
      this.toggleLoading();
      this.getProjects();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.search-bar {
  padding-left: 0.5rem;
  min-height: 2.5rem;
  min-width: 600px;

  @include searchBarOutline;
}

.search-wrapper {
  position: relative;

  & > .md-button {
    position: absolute;
    border-radius: $border-radius;
    right: -0.3rem;
  }
}

.md-fab .md-ripple,
.md-fab:before,
.md-icon-button .md-ripple,
.md-icon-button:before {
  border-radius: $border-radius;
}
</style>
