<template>
  <div v-if="projects.length === 0">
    <iq-empty-state icon="folder" title="No projects found" />
  </div>

  <div class="project-grid" v-else>
    <div
      class="project-grid--item"
      v-for="project in projects"
      :key="project.id"
      @dblclick="openProject(project.id)"
    >
      <!-- Header -->
      <div class="project-grid--item-header">
        <!-- Icon -->
        <md-avatar class="md-avatar-icon md-large">
          <md-icon class="md-primary">folder</md-icon>
        </md-avatar>

        <!-- Action -->
        <md-menu md-align-trigger>
          <md-button class="md-icon-button" md-menu-trigger>
            <md-icon>more_horiz</md-icon>
          </md-button>

          <md-menu-content>
            <md-menu-item @click="openProject(project.id)">
              <span>Projekt öffnen</span>
              <md-icon>launch</md-icon>
            </md-menu-item>

            <md-menu-item
              @click="handleProjectDelete(project.id)"
              v-if="isComponentActive(['admin'])"
            >
              <span>Projekt löschen</span>
              <md-icon>delete</md-icon>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>

      <!-- Body -->
      <div class="project-grid--item-body">
        <!-- Title & Status -->
        <div class="project-grid--item-body-title">
          <div class="md-title">{{ project.name }}</div>
          <iq-status-pill :status="project.status" />
        </div>

        <!-- Ref-ID -->
        <div class="md-caption">{{ project.ref_id }}</div>

        <!-- Description -->
        <!-- Max 120 characters -->
        <div class="md-body-2">{{ project.description | trimDescription }}</div>
      </div>

      <!-- Footer -->
      <div class="project-grid--item-footer">
        <div class="project-users">
          <span
            class="small-avatar clickable"
            v-for="(user, index) in project.users.slice(0, 4)"
            :key="user.id"
            :style="getUserIconPosition(index)"
          >
            {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
            <md-tooltip md-direction="right"
              >{{ user.first_name }} {{ user.last_name }}</md-tooltip
            >
          </span>
          <span
            v-if="project.users.length > 5"
            class="small-avatar clickable"
            :style="getUserIconPosition(5)"
          >
            {{ project.users.length - 5 }}+
            <md-tooltip md-direction="right"
              >{{ project.users.length - 5 }} weitere Benutzer</md-tooltip
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import accessControlMixin from "@/mixins/accessControlMixin";
import { deleteProject } from "@/services/api/project";

export default {
  name: "projectGrid",
  mixins: [accessControlMixin],
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDeletePromptActive: false,
      projectToDeleteId: null,
    };
  },
  filters: {
    trimDescription: function (value) {
      if (value.length > 120) return value.substring(0, 120) + "...";
      else return value;
    },
  },
  methods: {
    openProject(id) {
      this.$router.push({ name: "Project", params: { id: id } });
    },
    async handleProjectDelete(id) {
      if (!confirm("Sind Sie sicher, dass Sie dieses Projekt löschen möchten?"))
        return;
      try {
        await deleteProject(id);
        this.$emit("delete");
      } catch (error) {
        this.$emit(
          "error",
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!"
        );
        console.error(error);
      }
    },
    getUserIconPosition(index) {
      return `left: ${index * 25}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1rem;

  &--item {
    border-radius: $border-radius;
    padding: 2rem 1rem;
    background-color: white;

    @include borderHover;
    @include notSelectable;

    &:hover {
      cursor: pointer;
    }

    &-header {
      position: relative;

      & > .md-menu {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &-body {
      margin: 2rem 0;

      &-title {
        display: flex;
        align-items: center;

        & > .iq-pill {
          text-transform: capitalize;
          margin-left: $margin;
        }
      }

      & > .md-caption {
        margin-bottom: 1rem;
      }

      & > .md-body-2 {
        height: 48px;
        max-height: 48px;
        overflow: hidden;
      }
    }

    &-footer {
      display: flex;
      align-items: center;

      & > .project-users {
        display: flex;
        position: relative;
      }
    }
  }
}

.small-avatar {
  height: 32px;
  width: 32px;
  outline: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--iq-blue-500);
  background-color: var(--iq-blue-100);
  text-transform: uppercase;
  position: absolute;
  top: -15px;

  &:hover {
    z-index: 1000;
  }
}
</style>
